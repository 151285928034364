import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from './pages/Home';
import City from './pages/City';
import ErrorPage from './pages/ErrorPage';
import ComparePage from './pages/Compare';
import TrendPage from './pages/Trend';
import CityOverviewPage from './pages/CityOverviewPage';

const App: React.FC = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home/>,
      errorElement: <ErrorPage />,
    },
    {
      path: "city/:name",
      element: <City />,
    },
    {
      path: "compare/:metric/:city",
      element: <ComparePage />,
    },
    {
      path: "trend/:city",
      element: <TrendPage />,
    },
    {
      path: "overview", // Add the new route for the CityOverviewPage
      element: <CityOverviewPage />,
    },
  ], {basename: `${process.env.PUBLIC_URL}/`});

  return (
    <RouterProvider router={router} />
  );
};

export default App;
