import { Link, useParams } from "react-router-dom";
import BasePage from "./BasePage";
import { useTranslation } from "react-i18next";
import { ButtonGroup, Card, CardBody, CardSubtitle, CardText, CardTitle, Col, Container, Row } from "react-bootstrap";
import React from "react";
import CityChart from "../components/CityChart";

type DataPoint = {
  value: number;
  date: {
    year: number;
    month: number;
  };
  source: {
    title: string;
    url: string;
  };
};

type MonthlyCityData = {
  population: DataPoint;
  populationMonthlyChange: DataPoint;
  households: DataPoint;
};

type CityData = {
  [year: number]: {
    [month: number]: MonthlyCityData;
  };
};

const City: React.FC = () =>  {
  const { t } = useTranslation();
  const { name } = useParams<string>();
  const [cityData, setCityData] = React.useState<CityData>({});

  React.useEffect(() => {
    fetch(`/api/tokyo/cities/${name}.json`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        setCityData(data);
      })
      .catch((error) => console.log(error));
  }, [name]);

  const show_trend_button = false;

  const sortedYears = Object.keys(cityData).sort((a, b) => parseInt(b) - parseInt(a));
  const sortMonthsDesc = (months: string[]) => months.sort((a, b) => parseInt(b) - parseInt(a));

  return (
    <BasePage>
      <h2>{t(`cities.${name}`)}</h2>
      <Container>
      <Row>
          <Col>
            <CityChart cityData={cityData} />
          </Col>
      </Row>
      <Row xs="auto">
      {
       sortedYears.map(year => (
        sortMonthsDesc(Object.keys(cityData[parseInt(year)])).map(month => (
          Object.entries(cityData[parseInt(year)][parseInt(month)]).map(([metric_name, metric_data]) => (
              <Col key={`${year}-${month}-${metric_name}`} className="m-1 p-1">
              <Card style={{ maxWidth: '25rem' }}>
                <CardBody>
                  <CardTitle>{t(`metrics.${metric_name}`)}</CardTitle>
                  <CardSubtitle>{t(`metrics.as_of_date`, {"year": String(year), "month": String(month)})}</CardSubtitle>
                  <CardText>
                    <h1>{metric_data.value.toLocaleString("en-US")}</h1>
                  </CardText>
                  <ButtonGroup>
                    { show_trend_button ?
                      <Link to={`/trend/${name}`} className="btn btn-success">
                        {t(`site.menu.trend`)}
                      </Link>
                    : 
                      "" 
                    }
                    <Link to={`/compare/${metric_name}/${name}`} className="btn btn-success">
                      {t(`site.menu.compare`)}
                    </Link>
                  </ButtonGroup>
                </CardBody>
                <Card.Footer className="text-muted">
                  {t(`site.source`)}: <a href={metric_data.source.url} >{metric_data.source.title}</a>
                </Card.Footer>
              </Card>
              </Col>
            ))
          ))
        ))
      }
      </Row></Container>
    </BasePage>
  );
}

export default City;