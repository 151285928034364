import React, { useEffect, useState } from 'react';
import BasePage from './BasePage';
import { useTranslation } from 'react-i18next';
import TokyoMap from '../components/TokyoMap';
import { CityData } from '../components/CityOverviewCharts'

const Home: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [cityData, setCityData] = useState<CityData>({});
  const [geoJsonData, setGeoJsonData] = useState<any>(null);

  useEffect(() => {
    fetch('api/tokyo/cities/all.json')
      .then(response => response.json())
      .then(data => {
        setCityData(data);
      })
      .catch(error => console.error(error));

    fetch('api/maps/tokyo.geojson')
      .then(response => response.json())
      .then(data => {
        setGeoJsonData(data);
      })
      .catch(error => console.error(error));
  }, []);

  if (!geoJsonData) {
    return <div>Loading...</div>; // Show a loading state while data is being fetched
  }

  return (
    <BasePage>
      <h2>{t('prefectures.tokyo')}</h2>
      { cityData && geoJsonData ? ( <TokyoMap cityData={cityData} geoJsonData={geoJsonData} lang={i18n.language} />) : (<div>Loading...</div>) };
    </BasePage>
  );
};

export default Home;
