import { useTranslation } from 'react-i18next';
import { Container, Nav, Navbar } from "react-bootstrap";
import LanguagePicker from "../components/LanguagePicker";
import { Link } from "react-router-dom";

type Props = { children: React.ReactNode }

const BasePage: React.FC<Props> = ({children}: Props) =>  { 
  const { t } = useTranslation();

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Link to={"/"} className="navbar-brand">{t('site.title')}</Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Link to={"/"} className="nav-link">{t('site.menu.home')}</Link>
              <Link to={"/overview"} className="nav-link">{t('site.menu.overview')}</Link>
            </Nav>
          </Navbar.Collapse>
          <LanguagePicker />
        </Container>
      </Navbar>
      <Container className="p-3">
        {children}
      </Container>
    </>
  );
}
  
export default BasePage;
