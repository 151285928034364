import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Chart, ChartConfiguration, registerables } from 'chart.js';

Chart.register(...registerables);

type MyLineChartProps = {
  labels: string[];
  data: number[];
  label: string;
};

const LineChart: React.FC<MyLineChartProps> = (props) => {
  const chartContainer = useRef<HTMLCanvasElement>(null);
  const setChartInstance = useState<Chart | null>(null)[1];

  const chartConfig: ChartConfiguration = useMemo(() => {
    return {
      type: 'line',
      data: {
        labels: props.labels,
        datasets: [{
          label: props.label,
          data: props.data,
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }]
      }
    };
  }, [props.labels, props.label, props.data]);

  useEffect(() => {
    let newChartInstance: Chart | null = null;
      
    if (chartContainer.current) {
      newChartInstance = new Chart(chartContainer.current, chartConfig);
      setChartInstance(newChartInstance);
    } 

    return () => {
      if (newChartInstance) {
        newChartInstance.destroy();
      }
    };
  }, [chartConfig, setChartInstance]);

  return (
    <div>
      <canvas ref={chartContainer} />
    </div>
  );
}

export default LineChart;
