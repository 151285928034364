import { useTranslation } from "react-i18next";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import BasePage from "./BasePage";
import { Alert, Image } from "react-bootstrap";


const ErrorPage: React.FC = () =>  { 
    const { t } = useTranslation();

    const error = useRouteError();
    let errorMessage: string;
    let errorCode = -1;

    if (isRouteErrorResponse(error)) {
      // error is type `ErrorResponse`
      errorCode = error.status;
      errorMessage = `${error.status} `;
      errorMessage += error.data.message || error.statusText;
    } else if (error instanceof Error) {
      errorMessage = error.message;
    } else if (typeof error === 'string') {
      errorMessage = error;
    } else {
      console.error(error);
      errorMessage = 'Unknown error';
    }
      
    return (
      <BasePage>
        <div className="p-3">
          <Alert variant="danger">
            {t('Error')}: {t(errorMessage)}
          </Alert>
        </div>
        { errorCode > 0 && //equivalent to if(errorCode > 0)
          <div className="text-center">
            <Image src={`https://http.cat/${errorCode}.jpg`} rounded /> 
          </div>
        }
      </BasePage>
    )
}

export default ErrorPage;