import BasePage from "./BasePage";
import { useTranslation } from "react-i18next";
import { Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";

type PopulationData = {
  [key: string]: number;
};

const ComparePage: React.FC = () =>  { 
  const { t } = useTranslation();
  const { metric, city } = useParams<string>();
  const [citiesList, setCitiesList] = React.useState<PopulationData>({});

  const navigate = useNavigate();

  const handleRowClick = (cityName: string) => {
      navigate(`/city/${cityName}`);
  };

  React.useEffect(() => {
    fetch(`/api/tokyo/compare/${metric}.json`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        setCitiesList(data);
      })
      .catch((error) => console.log(error));
  }, [metric]);

  return (
    <BasePage>
      <h2>{t("site.menu.compare")}: {t(`metrics.${metric}`)}</h2>
      <Table hover style={{ maxWidth: '40rem' }}>
        <thead>
          <tr id="header">
            <th>{t(`metrics.name`)}</th>
            <th>{t(`metrics.${metric}`)}</th>
          </tr>
        </thead>
        <tbody>
        {
          Object.entries(citiesList).sort((a, b) => b[1] - a[1]).map(([listCity, population]) => (
              <tr id={listCity} className={listCity === city ? "table-success": ""} onClick={() => handleRowClick(listCity)}>
                <td>{t(`cities.${listCity}`)}</td>
                <td>{population.toLocaleString("en-US")}</td>
              </tr>
            )
          )
        }
        </tbody>
      </Table>
    </BasePage>
  );
}

export default ComparePage;