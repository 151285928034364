import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Container } from 'react-bootstrap';

type DataPoint = {
  value: number;
  date: {
    year: number;
    month: number;
  };
  source: {
    title: string;
    url: string;
  };
};

type MonthlyCityData = {
  population: DataPoint;
  populationMonthlyChange: DataPoint;
  households: DataPoint;
};

type CityData = {
  [year: number]: {
    [month: number]: MonthlyCityData;
  };
};

const parseCityData = (data: CityData) => {
  const labels: string[] = [];
  const populationData: number[] = [];
  const householdData: number[] = [];
  const monthlyChangeData: number[] = [];

  Object.keys(data).sort((a, b) => parseInt(a) - parseInt(b)).forEach(year => {
    Object.keys(data[parseInt(year)]).sort((a, b) => parseInt(a) - parseInt(b)).forEach(month => {
      const date = `${year}-${month.padStart(2, '0')}`;
      labels.push(date);
      populationData.push(data[parseInt(year)][parseInt(month)].population.value);
      householdData.push(data[parseInt(year)][parseInt(month)].households.value);
      monthlyChangeData.push(data[parseInt(year)][parseInt(month)].populationMonthlyChange.value);
    });
  });

  return { labels, populationData, householdData, monthlyChangeData };
};

type CityChartProps = {
  cityData: CityData;
};

type ChartData = {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
    fill: boolean;
    tension: number;
  }[];
};

const CityChart: React.FC<CityChartProps> = ({ cityData }) => {
  const [chartData, setChartData] = useState<ChartData>({
    labels: [],
    datasets: []
  });

  useEffect(() => {
    if (Object.keys(cityData).length > 0) {
      const { labels, populationData, householdData, monthlyChangeData } = parseCityData(cityData);

      setChartData({
        labels,
        datasets: [
          {
            label: 'Population',
            data: populationData,
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            fill: false,
            tension: 0.1,
          },
          {
            label: 'Households',
            data: householdData,
            borderColor: 'rgba(54, 162, 235, 1)',
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            fill: false,
            tension: 0.1,
          },
          {
            label: 'Monthly Change in Population',
            data: monthlyChangeData,
            borderColor: 'rgba(255, 206, 86, 1)',
            backgroundColor: 'rgba(255, 206, 86, 0.2)',
            fill: false,
            tension: 0.1,
          }
        ],
      });
    }
  }, [cityData]);

  return (
    <Container>
      <h2>City Data Chart</h2>
      <Line data={chartData} />
    </Container>
  );
};

export default CityChart;
