import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import TokyoGeoJSON from './TokyoGeoJSON';
import 'leaflet/dist/leaflet.css';
import './MapStyles.css'; // Import the CSS file for custom styles
import L from 'leaflet';
import { CityData } from '../components/CityOverviewCharts'

interface TokyoMapProps {
  cityData: CityData;
  geoJsonData: any;
  lang: string;
}

const TokyoMap: React.FC<TokyoMapProps> = ({ geoJsonData, cityData, lang }) => {
  const mapRef = useRef<L.Map | null>(null);
  const [isMapReady, setIsMapReady] = useState(false);

  const GetMap = () => {
    const map = useMap();
    useEffect(() => {
      if (map && !mapRef.current) {
        mapRef.current = map;
        setIsMapReady(true);
        console.log('Map instance set:', map); // Debug log
      }
    }, [map]);
    return null;
  };

  return (
    <MapContainer
      center={[35.575000, 139.500000]} // Adjusted to shift the view to the left
      zoom={11} // Zoom out by 2 levels
      minZoom={9.5} // Adjust the minimum zoom level
      className="map-container" // Add a className for custom styles
    >
      <GetMap />
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {isMapReady && geoJsonData && <TokyoGeoJSON data={geoJsonData} cityData={cityData} lang={lang} map={mapRef.current} />}
    </MapContainer>
  );
};

export default TokyoMap;
