import BasePage from "./BasePage";
import { useTranslation } from "react-i18next";
import LineChart from "../components/LineChart";

const TrendPage: React.FC = () =>  { 
  //const { city } = useParams<string>();
  const { t } = useTranslation();

  return (
    <BasePage>
      <h2>{t("site.menu.trend")}</h2>
      <LineChart 
        labels={['2018', '2019', '2020', '2021', '2022', '2023', '2024']} 
        data={[121800, 122100, 122000, 122500, 123000, 123356, 123456]} 
        label={t("metrics.population")} 
      />
    </BasePage>
  );
}

export default TrendPage;