import React from 'react';
import { Container } from 'react-bootstrap';
import CityOverviewCharts from '../components/CityOverviewCharts';
import BasePage from './BasePage';
import { useTranslation } from 'react-i18next';

const CityOverviewPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BasePage>
      <Container>
        <h1>{t('site.menu.overview')}</h1>
        <CityOverviewCharts />
      </Container>
    </BasePage>
  );
};

export default CityOverviewPage;
