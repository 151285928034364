import React, { useEffect, useState, useCallback } from 'react';
import { Line } from 'react-chartjs-2';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Select from 'react-select';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

Chart.register(ChartDataLabels);

export type DataPoint = {
  value: number;
  date: {
    year: number;
    month: number;
  };
  source: {
    title: string;
    url: string;
  };
};

export type MonthlyCityData = {
  population: DataPoint;
  populationMonthlyChange: DataPoint;
  households: DataPoint;
};

export type CityData = {
  [city: string]: {
    [year: number]: {
      [month: number]: MonthlyCityData;
    };
  };
};

type ChartData = {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
    fill: boolean;
    tension: number;
    city: string;
  }[];
};

const CityOverviewCharts: React.FC = () => {
  const [cityData, setCityData] = useState<CityData>({});
  const [selectedCities, setSelectedCities] = useState<string[]>([]);
  const [populationChartData, setPopulationChartData] = useState<ChartData>({ labels: [], datasets: [] });
  const [householdsChartData, setHouseholdsChartData] = useState<ChartData>({ labels: [], datasets: [] });
  const [monthlyChangeChartData, setMonthlyChangeChartData] = useState<ChartData>({ labels: [], datasets: [] });
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const calculateCityMetric = useCallback((data: CityData, city: string, metric: keyof MonthlyCityData): number => {
    if (!data[city]) return 0;
    return Object.values(data[city]).reduce((total, yearlyData) => {
      return (
        total +
        Object.values(yearlyData).reduce((yearlyTotal, monthlyData) => {
          return yearlyTotal + Number(monthlyData[metric].value);
        }, 0)
      );
    }, 0);
  }, []);

  const calculateCumulativeChange = useCallback((data: CityData, city: string): number => {
    if (!data[city]) return 0;
    const cumulativeChange = Object.values(data[city]).reduce((total, yearlyData) => {
      return (
        total +
        Object.values(yearlyData).reduce((yearlyTotal, monthlyData) => {
          return yearlyTotal + Number(monthlyData.populationMonthlyChange.value);
        }, 0)
      );
    }, 0);
    console.log(`City: ${city}, Cumulative Change: ${cumulativeChange}`);
    return cumulativeChange;
  }, []);

  const getTopCities = useCallback((data: CityData, count: number, metric: keyof MonthlyCityData) => {
    return Object.keys(data)
      .map(city => ({
        city,
        value: metric === 'populationMonthlyChange' ? calculateCumulativeChange(data, city) : calculateCityMetric(data, city, metric),
      }))
      .sort((a, b) => b.value - a.value)
      .slice(0, count)
      .map(item => item.city);
  }, [calculateCityMetric, calculateCumulativeChange]);

  const getBottomCities = useCallback((data: CityData, count: number, metric: keyof MonthlyCityData) => {
    return Object.keys(data)
      .map(city => ({
        city,
        value: metric === 'populationMonthlyChange' ? calculateCumulativeChange(data, city) : calculateCityMetric(data, city, metric),
      }))
      .sort((a, b) => a.value - b.value)
      .slice(0, count)
      .map(item => item.city);
  }, [calculateCityMetric, calculateCumulativeChange]);

  const calculatePercentageChange = useCallback((data: CityData, city: string): number => {
    if (!data[city]) return 0;
    const yearlyData = Object.values(data[city]);
    const firstYear = yearlyData[0];
    const lastYear = yearlyData[yearlyData.length - 1];
    const firstMonth = Object.values(firstYear)[0];
    const lastMonth = Object.values(lastYear)[Object.values(lastYear).length - 1];
    const initialPopulation = firstMonth.population.value;
    const finalPopulation = lastMonth.population.value;
    const percentageChange = ((finalPopulation - initialPopulation) / initialPopulation) * 100;
    console.log(`City: ${city}, Percentage Change: ${percentageChange}`);
    return percentageChange;
  }, []);

  useEffect(() => {
    fetch('/api/tokyo/cities/all.json')
      .then(response => response.json())
      .then(data => {
        setCityData(data);
        const topCities = getTopCities(data, 10, 'population');
        setSelectedCities(topCities);
      })
      .catch(error => console.log(error));
  }, [getTopCities]);

  const handleButtonClick = (type: 'top' | 'bottom' | 'growth' | 'drop') => {
    let cities: string[] = [];
    switch (type) {
      case 'top':
        cities = getTopCities(cityData, 10, 'population');
        break;
      case 'bottom':
        cities = getBottomCities(cityData, 10, 'population');
        break;
      case 'growth':
        cities = Object.keys(cityData)
          .map(city => ({
            city,
            value: calculatePercentageChange(cityData, city),
          }))
          .sort((a, b) => b.value - a.value)
          .slice(0, 10)
          .map(item => item.city);
        break;
      case 'drop':
        cities = Object.keys(cityData)
          .map(city => ({
            city,
            value: calculatePercentageChange(cityData, city),
          }))
          .sort((a, b) => a.value - b.value)
          .slice(0, 10)
          .map(item => item.city);
        break;
    }
    setSelectedCities(cities);
  };

  const parseOverviewData = useCallback((data: CityData, dataType: keyof MonthlyCityData, selectedCities: string[]) => {
    const labelsSet: Set<string> = new Set();
    const datasets: ChartData['datasets'] = [];

    selectedCities.forEach(city => {
      if (data[city]) {
        const cityDataset = {
          label: t(`cities.${city}`), // Use translation for the city name
          data: [] as number[],
          borderColor: '',
          backgroundColor: '',
          fill: false,
          tension: 0.1,
          city: city,
        };
        const cityData = data[city];

        Object.keys(cityData)
          .sort((a, b) => parseInt(a) - parseInt(b))
          .forEach(year => {
            Object.keys(cityData[parseInt(year)])
              .sort((a, b) => parseInt(a) - parseInt(b))
              .forEach(month => {
                const date = `${year}-${month.padStart(2, '0')}`;
                labelsSet.add(date);
                const dataPoint = cityData[parseInt(year)][parseInt(month)][dataType];
                cityDataset.data.push(Number(dataPoint.value));
              });
          });

        cityDataset.borderColor = `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`;
        cityDataset.backgroundColor = `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.2)`;

        datasets.push(cityDataset);
      }
    });

    const labels = Array.from(labelsSet).sort();
    console.log('Labels:', labels); // Debugging statement
    console.log('Datasets:', datasets); // Debugging statement

    return { labels, datasets };
  }, [t]);

  useEffect(() => {
    if (selectedCities.length > 0) {
      const populationData = parseOverviewData(cityData, 'population', selectedCities);
      const householdsData = parseOverviewData(cityData, 'households', selectedCities);
      const monthlyChangeData = parseOverviewData(cityData, 'populationMonthlyChange', selectedCities);

      setPopulationChartData(populationData);
      setHouseholdsChartData(householdsData);
      setMonthlyChangeChartData(monthlyChangeData);
    } else {
      setPopulationChartData({ labels: [], datasets: [] });
      setHouseholdsChartData({ labels: [], datasets: [] });
      setMonthlyChangeChartData({ labels: [], datasets: [] });
    }
  }, [cityData, selectedCities, parseOverviewData, i18n.language]);

  const handleChartClick = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const chart = Chart.getChart(event.currentTarget);
    if (!chart) return;

    const elements = chart.getElementsAtEventForMode(event.nativeEvent, 'nearest', { intersect: true }, true);
    if (elements.length > 0) {
      const element = elements[0];
      const datasetIndex = element.datasetIndex;
      const city = populationChartData.datasets[datasetIndex].city;
      navigate(`/city/${encodeURIComponent(city)}`);
    }
  };

  const getTextColor = (bgColor: string): string => {
    const color = bgColor.replace(/[^\d,]/g, '').split(',');
    const r = parseInt(color[0]);
    const g = parseInt(color[1]);
    const b = parseInt(color[2]);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 125 ? 'black' : 'white';
  };

  const cityOptions = Object.keys(cityData)
    .map(city => ({
      value: city,
      label: t(`cities.${city}`),
      population: calculateCityMetric(cityData, city, 'population'),
    }))
    .sort((a, b) => b.population - a.population);

  const selectedCityOptions = selectedCities.map(city => ({
    value: city,
    label: t(`cities.${city}`)
  }));

  const chartOptions = {
    plugins: {
      datalabels: {
        align: (context: any) => {
          const index = context.dataIndex;
          const total = context.dataset.data.length;
          const datasetIndex = context.datasetIndex;
          // Stagger labels based on dataset index
          if ((index + datasetIndex * 2) % Math.ceil(total / 4) === 0) {
            return 'end';
          } else if ((index + datasetIndex * 2) % Math.ceil(total / 4) === 1) {
            return 'start';
          }
          return 'center';
        },
        anchor: 'end' as const, // Ensure type is correctly inferred
        formatter: (value: number, context: any) => {
          const index = context.dataIndex;
          const total = context.dataset.data.length;
          const datasetIndex = context.datasetIndex;
          // Show label for every 20-25% of data points
          if ((index + datasetIndex * 2) % Math.ceil(total / 4) === 0) {
            return t(`cities.${context.dataset.city}`);
          }
          return null;
        },
        font: {
          size: 10,
        },
        backgroundColor: (context: any) => {
          return context.dataset.borderColor;
        },
        color: (context: any) => {
          return getTextColor(context.dataset.borderColor);
        },
        padding: 6,
        borderRadius: 4,
      },
    },
  };

  return (
    <Container>
      <Row>
        <Col>
          <Button variant="primary" onClick={() => handleButtonClick('top')}>{t('site.overview.top_10_population')}</Button>
          <Button variant="secondary" onClick={() => handleButtonClick('bottom')}>{t('site.overview.bottom_10_population')}</Button>
          <Button variant="success" onClick={() => handleButtonClick('growth')}>{t('site.overview.highest_growth')}</Button>
          <Button variant="danger" onClick={() => handleButtonClick('drop')}>{t('site.overview.highest_drop')}</Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            isMulti
            options={cityOptions}
            value={selectedCityOptions}
            onChange={selectedOptions => setSelectedCities(selectedOptions.map(option => option.value))}
            placeholder={t('site.overview.select_cities')}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>{t('site.overview.population_overview')}</h2>
          <Line data={populationChartData} options={chartOptions} onClick={handleChartClick} />
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>{t('site.overview.households_overview')}</h2>
          <Line data={householdsChartData} options={chartOptions} onClick={handleChartClick} />
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>{t('site.overview.monthly_population_change_overview')}</h2>
          <Line data={monthlyChangeChartData} options={chartOptions} onClick={handleChartClick} />
        </Col>
      </Row>
    </Container>
  );
};

export default CityOverviewCharts;
