import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import {default as en_site} from './en/site.json';
import {default as en_prefectures} from './en/prefectures.json';
import {default as en_cities} from './en/cities.json';
import {default as en_metrics} from './en/metrics.json';
import {default as en_literal} from './en/literal.json';
import {default as jp_site} from './jp/site.json';
import {default as jp_prefectures} from './jp/prefectures.json';
import {default as jp_cities} from './jp/cities.json';
import {default as jp_metrics} from './jp/metrics.json';
import {default as jp_literal} from './jp/literal.json';

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
  debug: true,
  fallbackLng: 'en',
  resources: {
    en: {
      translation: {
        site: en_site,
        prefectures: en_prefectures,
        cities: en_cities,
        metrics: en_metrics,
        ...en_literal
      }
    },
    jp: {
      translation: {
        site: jp_site,
        prefectures: jp_prefectures,
        cities: jp_cities,
        metrics: jp_metrics,
        ...jp_literal
      }
    },
  },
});
